import React, { useState } from 'react';
import { EmailLeadFormModal } from '@dmm/lib-react-ui-components';
import { ReCaptchaButtonWrapper, executeRecaptcha } from '../../components/ReCaptchaButtonWrapper';
import ReCaptchaV2 from '../../components/RecaptchaV2';
import * as captchaHelper from '../../utils/api/verifyRecaptchaHelper';
import {useDispatch} from 'react-redux';
import validate from '../../utils/dataValidation';
import { trackContactFormSubmit } from '../../store/actions/dataLayer';
import { addLead, trackPhoneCall } from '../../store/actions/dataLayer';
import { sendLead, prepareLeadDataForDealer } from '../../utils/api/leadHelper';
import get from 'lodash/get';
import {BOATTRADER_DEALER_SRP } from '../../constants/boats/';

const recaptchaV2Key = process.env.REACT_APP_GOOGLE_RECAPTCHAV2_KEY_ANTISPAM;
const captchaAction = 'BDP_LEAD_SUBMIT';

const ModalFormDealerWrapper = props => {
  const [invalidFields, setInvalidFields] = useState([]);
  const [showReCaptchaV2, setShowReCaptchaV2] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [leadData, setLeadData] = useState({});
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const dispatch = useDispatch();

  const setDefaultFormStatus = () => {
    setName('');
    setPhone('');
    setEmail('');
    setText('');
    setInvalidFields([]);
    setShowReCaptchaV2(false);
    setLeadData({});
    setShowSuccess(false);
    setShowError(false);
    setSubmitButtonDisabled(false);
  };
  const closeModal = () => {
    setDefaultFormStatus();
    props.onClose();
  };

  const addEmailLeadData = (id) => {
    dispatch(addLead(id, 'email lead', 'email lead'));
  };

  const trackContactFormSubmitData = (resp) => {
    dispatch(trackContactFormSubmit({'leadId': get(resp, 'data.id')}));
  };

  const setLeadSuccessState = () => {
    setShowSuccess(true);
  };

  const setLeadErrorState = () => {
    setShowError(true);
  };

  const handleOnCallClick = () => {
    dispatch(trackPhoneCall());
    dispatch(addLead(props.dealer.id, 'phone lead', undefined, 'modal'));
    window.open(`tel:${props.dealer.phoneNumber}`, '_self');
  };

  const sendLeadSucceed = (leadData) => {
    const { id } = leadData;
    return sendLead(leadData).then(resp => {
      if (resp.status === 200) {
        addEmailLeadData(id);
        trackContactFormSubmitData(resp);
        return true;
      }
      return false;
    }).catch(() => {
      return false;
    });
  };

  const handleRecaptchaV2Change = async (success) => {
    if (success) {
      const isLeadSent = await sendLeadSucceed(leadData);
      if (isLeadSent) {
        setLeadSuccessState();
      } else {
        setLeadErrorState();
      }
    }
  };

  const onFormSubmit = async () => {
    const invalidFields = validateAllFields();
    if (invalidFields.length > 0){
      setInvalidFields(invalidFields);
      return;
    }
    setSubmitButtonDisabled(true);
    const customerInfo = {
      name,
      email,
      comments: text,
      phone,
      zip: ''
    };
    const ld = prepareLeadDataForDealer(props.dealer, BOATTRADER_DEALER_SRP, customerInfo);
    setLeadData(ld);
    const isCaptchaSucceed = await captchaHelper.passCaptchaChallenge(ld, captchaAction, executeRecaptcha);
    if (!isCaptchaSucceed) {
      setShowReCaptchaV2(true);
      return;
    }
    const isLeadSent = await sendLeadSucceed(ld);
    setSubmitButtonDisabled(false);
    if (isLeadSent) {
      setLeadSuccessState(ld);
    } else {
      setLeadErrorState();
    }
  };

  const updateInvalidFields = (fieldType, isValid) => {
    if (isValid) {
      setInvalidFields(invalidFields.filter(field => field !== fieldType));
    } else {
      if (!invalidFields.includes(fieldType)) {
        const newInvalidFields = [...invalidFields, fieldType];
        setInvalidFields(newInvalidFields);
      }
    }
  };

  const validateAndUpdateField = (fieldType, fieldValue) => {
    const isValid = validate(fieldValue, fieldType);
    updateInvalidFields(fieldType, isValid);
  };

  const validateAllFields = () => {
    const invalidFields = [];
    const isValidName = validate(name, 'name');
    const isValidEmail = validate(email, 'email');
    const isValidPhone = validate(phone, 'phone');
    const isValidText = validate(text, 'text');
    if (!isValidName){
      invalidFields.push('name');
    }
    if (!isValidEmail){
      invalidFields.push('email');
    }
    if (!isValidPhone){
      invalidFields.push('phone');
    }
    if (!isValidText){
      invalidFields.push('text');
    }
    return invalidFields;
  };

  return <ReCaptchaButtonWrapper>
    <EmailLeadFormModal
      buttonDisabled={submitButtonDisabled}
      onClose={closeModal}
      show={props.show}
      id={'dealerModalForm'}
      onSubmit={onFormSubmit}
      sellerName={{
        ...props.dealer,
        phoneText: 'Call Now',
        displayPhoneMessage: true,
        onClickPhoneMessage: handleOnCallClick
      }}
      invalidFields={invalidFields}
      showSuccessMessage={showSuccess}
      showErrorMessage={showError}
      name={{
        id: 'nameInputForm',
        name: 'name',
        value: name,
        onChange: (e) => setName(e.target.value),
        onBlur: (e) => validateAndUpdateField('name', e.target.value),
      }}
      email={{
        id: 'emailInputForm',
        name: 'email',
        value: email,
        onChange: (e) => setEmail(e.target.value),
        onBlur: (e) => validateAndUpdateField('email', e.target.value),
      }}
      phone={{
        id: 'phoneInputForm',
        name: 'phone',
        value: phone,
        onChange: (e) => setPhone(e.target.value),
        onBlur: (e) => validateAndUpdateField('phone', e.target.value),
      }}
      textarea={{
        name: 'text',
        value: text,
        id: 'textInputForm',
        onChange: (e) => setText(e.target.value),
        onBlur: (e) => validateAndUpdateField('text', e.target.value),
      }}
    />
    { showReCaptchaV2 && (
      <div data-testid="recaptcha-wrapper" className="recaptcha-wrapper">
        <ReCaptchaV2
          sitekey={recaptchaV2Key}
          onChange={handleRecaptchaV2Change}
        />
      </div>
    )}
  </ReCaptchaButtonWrapper>;
};

export default ModalFormDealerWrapper;
