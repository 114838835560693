import React from 'react';
import YouTube from 'react-youtube';
import { getYouTubeIdFromUrl, isYouTubeVideo } from '../../../utils/youtube';


export const BDPMainGalleryMobileItem = ({ item, index, isModal }) => {
  if (
    item.mediaType === 'external' &&
    isYouTubeVideo(item.src)
  ) {
    return (
      <div
        className="bdp-gallery-mobile-carousel"
      >
        {!isModal && <div className="video-player-holder" />}
        <YouTube
          videoId={getYouTubeIdFromUrl(item.src)}
          title={item.title}
          iframeClassName="video-player"
        />
      </div>
    );
  }
  if (item.mediaType === 'video') {
    return (
      <div
        className="bdp-gallery-mobile-carousel bdp-gallery-mobile-carousel--video"
      >
        {!isModal && <div className="video-player-holder" />}
        <video controls preload="none" className="video-player" title={item.title} poster={item.poster}>
          <source src={item.src} type="video/mp4" />
        </video>
      </div>
    );
  }

  if (!item.mediaType) {
    if (isModal) {
      return (
        <img
          src={item.src}
          srcSet={item.srcset}
          sizes={item.sizes}
          className="stacked-gallery-item"
          alt={item.alt}
        />
      );
    }

    return (
      <div
        className="bdp-gallery-mobile-carousel"
      >
        <picture className="stacked-gallery-image">
          {item.sources?.map((source, index) => (
            <source
              key={`${source.media}-${index}`}
              media={source.media}
              srcSet={source.srcset}
            />
          ))}
          <img
            loading={index >= 1 ? 'lazy' : undefined}
            src={item.src}
            alt={item.alt}
            className="stacked-gallery-image"
          />
        </picture>
      </div>
    );
  }

  return null;
};

export default BDPMainGalleryMobileItem;
