import { EmailLeadForm } from '@dmm/lib-react-ui-components';
import classNames from 'classnames';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReCaptchaButtonWrapper, executeRecaptcha } from '../../../components/ReCaptchaButtonWrapper';
import ReCaptchaV2 from '../../../components/RecaptchaV2';
import { AD_PORTAL_LEAD_SOURCE, BOATTRADER_BRAND_SHOWCASE, BRAND_BANNER_IMPRESSION } from '../../../constants/boats';
import { withABTest } from '../../../server/abTest/withABTest';
import { addLead, setOemBrandProductImpression, trackContactFormSubmit, trackPhoneCall, addFinanceAdvantageEmailLead } from '../../../store/actions/dataLayer';
import { prepareLeadData, sendLead } from '../../../utils/api/leadHelper';
import * as captchaHelper from '../../../utils/api/verifyRecaptchaHelper';
import { BRANCHIO_CONTENT_TYPES, BRANCH_EVENT, branchIoEventsManager } from '../../../utils/branchIoEventsManager';
import { isEventSent } from '../../../utils/dataLayerHelper';
import validate from '../../../utils/dataValidation';
import useBranchIoMetaTag from '../../../utils/hooks/useBranchIoMetaTag';
import { LOGO_SIZES, getResizedImageSrc, getTitle, isFSBOContact } from '../../../utils/listingHelper';
import { nil } from '../../../utils/runOnceHelper';
import { createInquiryFinanceLead, sendFinanceLead } from '../../../utils/trident';
import { interestedInFinanceCheckboxExperiment } from '../../../utils/trident/Experiments';
import { handleKameleoonGoal, useFeatureFlags } from '../../../utils/experiment/kameleoonHelper';
import { GOAL_ALL_BDP_EMAIL_LEAD_FORM } from '../../../constants/goals';
import { BOAT_DETAIL_CTA_LABEL } from '../../../constants/boatDetails';


const recaptchaV2Key = process.env.REACT_APP_GOOGLE_RECAPTCHAV2_KEY_ANTISPAM;
const captchaAction = 'BDP_LEAD_SUBMIT';

const BDPEmailLeadForm = ({
  contactFormPosition = 'one-',
  listing,
  initialValues,
  onSuccess = nil,
  onError = nil,
  hideForm = false,
  abTestConfiguration,
  buttonAttributes = {},
}) => {

  const { BranchIoMetaTagComponent, fireBranchioMetaTag } = useBranchIoMetaTag();

  const privateSellerMessage = 'This boat is listed by a private seller. Watch out for really cheap deals - they may be too good to be true. Make sure you see the boat for yourself and know who you\'re dealing with.';
  const listingTitle = getTitle(listing);
  const [name, setName] = useState(initialValues?.name || '');
  const [email, setEmail] = useState(initialValues?.email || '');
  const [phone, setPhone] = useState(initialValues?.phone || '');
  const [zip, setZip] = useState(initialValues?.zip || '');
  const [interestedInFinance, setInterestInFinance] = useState(initialValues?.interestedInFinance || true);
  const [message, setMessage] = useState(`I\'m interested in getting more information about your ${listingTitle}. Please contact me.`);
  const [invalidFields, setInvalidFields] = useState([]);
  const [showReCaptchaV2, setShowReCaptchaV2] = useState(false);
  const [leadData, setLeadData] = useState({});
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [oemBrandProductImpressionSent, setOemBrandProductImpressionSent] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const showFinanceCheckbox = interestedInFinanceCheckboxExperiment.isActive(abTestConfiguration, listing);
  const isFinanceableBrokerDealerFinanceAdvantageLead = interestedInFinanceCheckboxExperiment.isSegmentCandidate(listing);
  const dispatch = useDispatch();

  const {featureFlagBDPLeadFormPhoneRequired}  = useFeatureFlags();

  const handlePhoneCall = () => {
    dispatch(trackPhoneCall());
    dispatch(addLead(listing.Id, 'phone call'));
  };

  const setOemBrandProductImpressionData = (listing) => {
    if (isEventSent('pageview') && !oemBrandProductImpressionSent) {
      dispatch(setOemBrandProductImpression(
        listing.oemDetails.brandId,
        BRAND_BANNER_IMPRESSION,
        BRAND_BANNER_IMPRESSION
      ));
      setOemBrandProductImpressionSent(true);
    }
  };

  const addEmailLeadData = (listingId) => {
    dispatch(addLead(listingId, 'email lead'));
  };

  const trackContactFormSubmitData = (resp) => {
    dispatch(trackContactFormSubmit(
      {'leadId': get(resp, 'data.id'),
        ...(showFinanceCheckbox ? {'financing_flag': interestedInFinance} : {})
      }));
  };


  const updateInvalidFields = (fieldType, isValid) => {
    if (isValid) {
      setInvalidFields(invalidFields.filter(field => field !== fieldType));

    } else {
      if (!invalidFields.includes(fieldType)) {
        const newInvalidFields = [...invalidFields, fieldType];
        setInvalidFields(newInvalidFields);
      }
    }
  };

  const getInvalidKey = (val, key)  => {
    return validate(val, key, featureFlagBDPLeadFormPhoneRequired) ? '' : key;
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const validateAllFields = (isOemModel) => {

    const fieldsToValidate = [
      { value: name, type: 'name' },
      { value: email, type: 'email' },
      { value: phone, type: 'phone' },
      { value: message, type: 'text' }
    ];

    if (isOemModel) {
      fieldsToValidate.push({ value: zip, type: 'zip' });
    }

    return fieldsToValidate.map(({ value, type }) => getInvalidKey(value, type)).filter(val => val !== '');
  };

  const setDefaultFormStatus = () => {
    setName('');
    setEmail('');
    setPhone('');
    setZip('');
    setInterestInFinance(true);
    setLeadData({});
    setMessage(`I\'m interested in getting more information about your ${listingTitle}. Please contact me.`);
    setInvalidFields([]);
    setShowError(false);
    setShowReCaptchaV2(false);
    setSubmitButtonDisabled(false);
  };

  const setLeadSuccessState = (ld = leadData) => {
    setDefaultFormStatus();
    setShowSuccess(true);
    onSuccess(ld);
  };

  const setLeadErrorState = () => {
    setShowError(true);
    onError();
  };

  const handleSubmit = async () => {
    const checkedInvalidFields = validateAllFields(listing.isOemModel);
    const isValidForm = checkedInvalidFields.length === 0;
    if (!isValidForm) {
      setInvalidFields(checkedInvalidFields);
      return;
    }
    setSubmitButtonDisabled(true);
    fireBranchioMetaTag(BRANCHIO_CONTENT_TYPES.CONTACT_SELLER);
    const formFields = {
      name,
      email,
      phone,
      zip,
      comments: message
    };
    const ld = prepareLeadData(listing, formFields, listing.isOemModel ? BOATTRADER_BRAND_SHOWCASE : AD_PORTAL_LEAD_SOURCE);
    setLeadData(ld);
    const isCaptchaSucceed = await captchaHelper.passCaptchaChallenge(ld, captchaAction, executeRecaptcha);
    if (!isCaptchaSucceed) {
      setShowReCaptchaV2(true);
      return;
    }
    const isLeadSent = await sendLeadSucceed(ld);
    setSubmitButtonDisabled(false);
    if (isLeadSent) {
      const dataTestingClass = buttonAttributes['data-testing-class'];
      handleKameleoonGoal(GOAL_ALL_BDP_EMAIL_LEAD_FORM, dataTestingClass);
      setLeadSuccessState(ld);
    } else {
      setLeadErrorState();
    }

    if (isFinanceableBrokerDealerFinanceAdvantageLead) {
      addFinanceAdvantageEmailLead();
    }

    if (featureFlagBDPLeadFormPhoneRequired){
      window.kameleoonQueue.push(['Goals.processConversion', 'custom-email-lead-has-phone-number']);
    }


    if (showFinanceCheckbox && interestedInFinance) {
      const inquiryFinanceLead = await createInquiryFinanceLead(formFields, listing);
      try {
        await sendFinanceLead(inquiryFinanceLead);
      } catch {
        // Error is logged in the console already
      }
    }
  };

  const validateAndUpdateField = (fieldType, fieldValue, isRequired = false) => {
    const isValid = validate(fieldValue, fieldType, isRequired);
    updateInvalidFields(fieldType, isValid);
  };

  const handleRecaptchaV2Change = async (success) => {
    if (success) {
      const isLeadSent = await sendLeadSucceed(leadData);
      if (isLeadSent) {
        setLeadSuccessState();
      } else {
        setLeadErrorState();
      }
    }
  };

  const sendLeadSucceed = (leadData) => {
    const { id: listingId } = leadData;
    return sendLead(leadData).then(resp => {
      if (resp.status === 200) {
        addEmailLeadData(listingId);
        trackContactFormSubmitData(resp);
        fireBranchioMetaTag(BRANCHIO_CONTENT_TYPES.CONTACT_SUBMITTED);
        branchIoEventsManager(BRANCH_EVENT.CONTACT_SUBMIT, {
          sku: get(listing, 'id', ''),
          product_name: get(listing, 'model', ''),
          product_brand: get(listing, 'validMake', ''),
        });
        return true;
      }
      return false;
    }).catch(() => {
      return false;
    });
  };

  const renderFinanceCheckbox = ({ interestedInFinance, setInterestInFinance }) => {
    return (
      <div className="financing-checkbox">
        <input
          type="checkbox"
          id="financing"
          name="financing"
          onChange={(e) => setInterestInFinance(e.target.checked)}
          checked={interestedInFinance}
        />
        <label htmlFor="financing">{'I want information on financing.'}</label>
      </div>
    );
  };


  const address = listing.contact?.address || {};
  const addressString = `${address.street || ''}, ${address.city || ''}, ${address.state || ''}, ${address.zip || ''}`;
  return (
    <div data-testid="email-lead-form"  data-e2e="email-lead-form" className={classNames('email-lead-form', {'hidden': hideForm})}>
      <BranchIoMetaTagComponent />
      <ReCaptchaButtonWrapper>
        <EmailLeadForm
          portal="bt"
          oemLeadLogo={{
            src: listing.isOemModel ? getResizedImageSrc(listing.oemDetails, LOGO_SIZES.emailLeadFormLogo.w, LOGO_SIZES.emailLeadFormLogo.h) : '',
            alt: listing.isOemModel ? `${listing.oemDetails.name || 'brand'} logo` : '',
            className: listing.isOemModel ?  'brand-logo' : '',
            onError: listing.isOemModel ? handleImageError : undefined,
            onLoad: listing.isOemModel && !imageError ? setOemBrandProductImpressionData(listing) : undefined
          }}
          sellerName={{
            name: isFSBOContact(listing.contact) ? BOAT_DETAIL_CTA_LABEL : 'Contact ' + listing.contact?.name,
            address: isFSBOContact(listing.contact) ? get(listing.contact, 'name') : addressString,
            phoneNumber: listing.contact?.phone || '',
            onClick: (e) => handlePhoneCall(false, e)
          }}
          name={{
            id: `${contactFormPosition}name`,
            name: 'name',
            value: name,
            onChange: (e) => setName(e.target.value),
            onBlur: (e) => validateAndUpdateField('name', e.target.value),
          }}
          email={{
            id: `${contactFormPosition}email`,
            name: 'email',
            value: email,
            onChange: (e) => setEmail(e.target.value),
            onBlur: (e) => validateAndUpdateField('email', e.target.value),
          }}
          phone={{
            id: `${contactFormPosition}phone`,
            label: featureFlagBDPLeadFormPhoneRequired && 'Your Phone',
            name: 'phone',
            value: phone,
            onChange: (e) => setPhone(e.target.value),
            onBlur: (e) => validateAndUpdateField('phone', e.target.value, featureFlagBDPLeadFormPhoneRequired),
          }}
          zip={{
            id: listing.isOemModel ? `${contactFormPosition}zip` : '',
            name: listing.isOemModel ? 'zip' : '',
            value: listing.isOemModel ? zip : '',
            onChange: listing.isOemModel ? (e) => setZip(e.target.value) : '',
            onBlur: listing.isOemModel ? (e) => validateAndUpdateField('zip', e.target.value) : '',
          }}
          textarea={{
            name: 'text',
            value: isFSBOContact(listing.contact) ? '' : message,
            id: `${contactFormPosition}emailLeadForm`,
            onChange: (e) => setMessage(e.target.value),
            onBlur: (e) => validateAndUpdateField('text', e.target.value),
          }}
          invalidFields={invalidFields}
          successMessage={(isFSBOContact(listing.contact) && privateSellerMessage)}
          showSuccessMessage={showSuccess}
          showErrorMessage={showError}
          buttonDisabled={submitButtonDisabled}
          buttonAttributes={buttonAttributes}
          onSubmit={handleSubmit}>
          { showReCaptchaV2 && (
            <div data-testid="recaptcha-wrapper" className="recaptcha-wrapper">
              <ReCaptchaV2
                sitekey={recaptchaV2Key}
                onChange={handleRecaptchaV2Change}
              />
            </div>
          ) }
        </EmailLeadForm>
        { showFinanceCheckbox && renderFinanceCheckbox({ interestedInFinance, setInterestInFinance }) }
      </ReCaptchaButtonWrapper>
    </div>
  );
};


export default withABTest(BDPEmailLeadForm);
