import React from 'react';
import DefaultLayout from '../../layouts/Default';
import MainContent from './MainContent';

const MyBoats = (props) => {
  return (
    <DefaultLayout {...props} pageType="MyBoats">
      <MainContent {...props} />
    </DefaultLayout>
  );
};

export default MyBoats;

