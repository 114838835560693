import React, { PureComponent } from 'react';
import { Notifier } from '@airbrake/browser';

import DefaultLayout from '../../layouts/Default';
import ErrorMessage from '../../components/ErrorMessage';

export default class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, info: null };
    this.airbrake = new Notifier({
      projectId: process.env.REACT_APP_AIRBRAKE_ID,
      projectKey: process.env.REACT_APP_AIRBRAKE_KEY
    });
  }

  componentDidCatch(error, info) {
    console.log('error catch: ', error);
    console.log('error catch info: ', info);
    this.setState({ hasError: true, error: error, info: info });
    let envString;
    if (['production', 'development'].includes(process.env.NODE_ENV)) {
      envString = process.env.NODE_ENV;
    }
    else {
      envString = 'qa';
    }

    this.airbrake.notify({
      error: error,
      environment: envString,
      params: {info: info}
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <DefaultLayout {...this.props} pageType="notFound">
          <ErrorMessage {...this.props} message="Please reset your search and try again." altUrl="/boats"/>
        </DefaultLayout>
      );
    }
    return this.props.children;

  }
}
