import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTitle } from '../../utils/listingHelper';
import { setProductImpression } from '../../store/actions/dataLayer';
import { HorizontalGallery } from '@dmm/lib-react-ui-components';
import { getBreakpoint } from '../../utils/commonHelper';
import { ListingCarouselItem } from './ListingCarouselItem';
import './styles.css';

export const ListingsCarousel = (
  {
    listings = [],
    listingsType,
    title, subtitle,
    listingTrackingClick,
    page,
    locationMarketingMessage = false
  }) => {
  const dispatch = useDispatch();
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    setIsDesktop(getBreakpoint() === 'desktop');
    listings.forEach((listing, position) => {

      let { id } = listing;
      dispatch(setProductImpression(id, listingsType, position, undefined, undefined, undefined, undefined, undefined, getTitle(listing), listing.make));
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (listings.length === 0){
    return null;
  }

  return ( <>
    <div className="listingCarouselContainer" data-e2e="listingCarouselContainer">
      <HorizontalGallery
        title = {title}
        subtitle = {subtitle}
        leftButtonAttributes = {{
          'data-reporting-click-internal-link-type': 'click',
          'data-reporting-click-internal-link-id': 'Move Slider Left',
          'data-e2e': 'move-slider-left'
        }}
        rightButtonAttributes = {{
          'data-reporting-click-internal-link-type': 'click',
          'data-reporting-click-internal-link-id': 'Move Slider Right',
          'data-e2e': 'move-slider-right'
        }}
      >
        {listings.map((listing, index) =>
          <div key={`${listingsType}CarouselElement${index}`} className="carouselElement">
            <ListingCarouselItem
              index={index}
              isDesktop={isDesktop}
              listing={listing}
              listingTrackingClick={listingTrackingClick}
              listingsType={listingsType}
              page={page}
              sellerMarketingMessage={locationMarketingMessage}/>
          </div>
        )
        }
      </HorizontalGallery>
    </div>
  </>
  );
};
