export const BOAT_DETAILS_URL_PATTERN = '/boat/:id([^/]+)';
export const MAX_CAROUSEL_THUMBNAILS_WIDTH = 880;
export const CAROUSEL_THUMBNAILS_NUMBER = 5;
export const ENABLE_BDP_BESTIMATE = true;
export const BESTIMATE_PORTAL_LEAD_SOURCE = 'BoatTrader Bestimate';
export const POST_LEAD_SIMILAR_BOATS_MODAL = 'Post Lead Similar Boats Modal';
export const FSBO = 'FSBO';
export const COMMERCIAL = 'Commercial';
export const BOAT_DETAIL_CTA_LABEL = 'Contact Seller';

/* Cookies */
export const UNAVAILABILITY_REDIRECT_COOKIE = 'unavailabilityRedirect';
export const REDUCTION_REDIRECT_COOKIE = 'reductionRedirect';

export const MARKETING_PARAMS_TO_OVERRIDE = [
  'utm_campaign', 'utm_medium', 'utm_source'
];

